import React from 'react';

import classes from './Box.module.css';

const Box = ({ icon, children }) => {
  return (
    <div className={classes.box}>
      <i className={`fa fa-2 fa-${ icon } ${classes.fa}`}></i>
      { children }
    </div>
  )
}

export default Box;
