import React from 'react';

const TechStack = () => {

  const baseUrl = 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/'

  return (
    <ul className="devicons-list">
      <li><img src={`${ baseUrl }html5/html5-original.svg`} title="HTML5" alt="HTML5" /></li>
      <li><img src={`${ baseUrl }css3/css3-original.svg`} title="CSS3" alt="CSS3" /></li>
      <li><img src={`${ baseUrl }javascript/javascript-original.svg`} title="JavaScript" alt="JavaScript" /></li>

      <li><img src={`${ baseUrl }bootstrap/bootstrap-original.svg`} title="Bootstrap" alt="Bootstrap" /></li>
      <li><img src={`${ baseUrl }sass/sass-original.svg`} title="Sass" alt="Sass" /></li>
      <li><img src={`${ baseUrl }angularjs/angularjs-original.svg`} title="Angular" alt="Angular" /></li>
      <li><img src={`${ baseUrl }react/react-original.svg`} title="React" alt="React" /></li>
      <li><img src={`${ baseUrl }redux/redux-original.svg`} title="Redux" alt="Redux" /></li>
      <li><img src={`${ baseUrl }typescript/typescript-original.svg`} title="TypeScript" alt="TypeScript" /></li>
      <li><img src={`${ baseUrl }nodejs/nodejs-original.svg`} title="Node.js" alt="Node.js" /></li>
      <li><img src={`${ baseUrl }mongodb/mongodb-original.svg`} title="mongoDB" alt="mongoDB" /></li>

      <li><img src={`${ baseUrl }npm/npm-original-wordmark.svg`} title="npm" alt="npm" /></li>
      <li><img src={`${ baseUrl }firebase/firebase-plain.svg`} title="Firebase" alt="Firebase" /></li>
      <li><img src={`${ baseUrl }git/git-original.svg`} title="Git" alt="Git" /></li>
      <li><img src={`${ baseUrl }photoshop/photoshop-plain.svg`} title="Adobe Photoshop" alt="Adobe Photoshop" /></li>
      <li><img src={`${ baseUrl }illustrator/illustrator-plain.svg`} title="Adobe Illustrator" alt="Adobe Illustrator" /></li>
      <li><img src={`${ baseUrl }windows8/windows8-original.svg`} title="Windows" alt="Windows" /></li>
      <li><img src={`${ baseUrl }apple/apple-original.svg`} title="macOS" alt="macOS" className="invert" /></li>
    </ul>
  )
}

export default TechStack
