import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../UI/Logo';

import classes from './SideMenu.module.css';

function SideMenu() {
  const [isOpen, toggleMenu] = useState(false);

  function onMenuToggle() {
    toggleMenu(!isOpen)
  }

  const buttonClasses = ['fa', 'fa-2', 'fa-times'];

  if(!isOpen) {
    buttonClasses.push('fa-bars')
  }

  return (
    <>
      <menu className={ `${classes.menu} ${isOpen ? classes.open : classes.closed}` }>
        <button className={classes.closeButton} onClick={onMenuToggle}><i className={buttonClasses.join(' ')}></i></button>
        <Logo />
        <nav>
          <ul>
            <li><NavLink to="/about" activeClassName={classes.active}  exact onClick={onMenuToggle}>About</NavLink></li>
            <li><NavLink to="/portfolio" activeClassName={classes.active} exact onClick={onMenuToggle}>Portfolio</NavLink></li>
          </ul>
        </nav>
      </menu>
      { isOpen && <div className={classes.backdrop} onClick={onMenuToggle}></div>}
    </>  
  );
}

export default SideMenu;
