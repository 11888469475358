import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Header from './components/Header/Header';
import SideMenu from './components/SideMenu/SideMenu';
import About from './pages/About';
import Portfolio from './pages/Portfolio';

import './App.css';

function App() {
  return (
    <>
      <SideMenu />
      <Header />
      <section>
        <Switch>
          <Route path="/about" component={ About } />
          <Route path="/portfolio" component={ Portfolio } />
          <Redirect from="/" to="/about" />
        </Switch>
      </section>
    </>  
  );
}

export default App;
