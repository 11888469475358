import React, { useState, useEffect } from 'react';
import CardItem from './CardItem';

const WorkPage = () => {

  const [works, setWorks] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = async () => {
    setIsLoading(true)
    const response = await fetch(process.env.REACT_APP_FB_URL)
    
    if(!response.ok) {
      throw new Error('Failed to fetch!!!')
    }

    const data = await response.json()
    let itemList = []

    for(let key of Object.keys(data)) {
      itemList.push({
        ...data[key],
        id: key
      })
    }
    
    setWorks(itemList)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])



  return (
    <>
      <h2>Work</h2>
      <p>Here you can have a look at some projects I've been working on.</p>

      { isLoading && <div className="loading-spinner"></div> }
      { !isLoading && (
          <ul className="cards">
            {
              works.map(work => (
                <CardItem { ...work } key={ work.title } />  
              ))
            }
          </ul>
        )
      }
    </>
  );
};
export default React.memo(WorkPage);
