import React from 'react';

import Card from '../UI/Card'

const AboutMe = () => {
  return (
    <div className="col">
      <Card>
        <h2>About Me</h2>
        <p>Hello, my name is Javier and I work on User Interface Development since 2002.</p>
        <p>
           For some years I have travelled and worked with many companies having the opportunity 
           to collaborate in various roles; from Front End Developer to Product Design Manager, 
           in several product companies and advertising agencies from Argentina, Mexico, Australia, 
           New Zealand, United Kingdom, Chile and Spain.
        </p>
        
        <p>Thanks for taking the time to check out some of my work.</p>
      </Card>
    </div>
  )
}

export default AboutMe;
