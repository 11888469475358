import React from "react";

import Card from '../UI/Card'

const thumbSrc = slug => {
  return slug.split(' ').join('-').toLowerCase()
}


const CardItem = ({ title, description, url, tags }) => {

  return (
    <li>
      <Card>
        <a href={ url } rel="noopener noreferrer" target="_blank">
          <img src={`thumbs/${thumbSrc(title)}.png`} alt={ title } className="thumbnail" />
        </a>
        <div>
          <h5><a href={ url } rel="noopener noreferrer" target="_blank">{ title }</a></h5>
          <p>{ description }</p>
          <div className="tags">
            { tags.map(tag => {
              let iconTag = tag.toLowerCase()
              let iconClass = (iconTag === 'photoshop' || iconTag === 'illustrator') ? 'plain' : 'original'
              let iconUrl = `https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${ iconTag }/${ iconTag }-${ iconClass }.svg`
                return <img src={ iconUrl } title={ tag } alt={ tag } key={ tag } />
                // <i className={`devicon-${ tag.toLowerCase() }-plain colored`} title={tag}></i>
              }) 
            }
          </div>
        </div>
      </Card>
    </li>
  )
}

export default CardItem;
