import React from 'react'
import AboutMe from '../components/About/AboutMe'
import Contact from '../components/About/Contact'

const About = () => {
  return (
    <div className="row">
      <AboutMe />
      <Contact />
    </div>
  )
}

export default About
