import React from 'react';

import Box from '../UI/Box';
import TechStack from './TechStack';

const Contact = () => {
  return (
    <div className="col items">
      <Box icon="code">
        <h2>Technologies I work with</h2>
        <TechStack />
      </Box>
      <Box icon="envelope">
        <h2>Get in touch</h2>
        <a href="https://www.linkedin.com/in/javirocha/" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-original.svg" alt="LinkedIn" />
          <strong>javirocha</strong>
        </a>
      </Box>
      <Box icon="file-text">
      <h2>Resume</h2>
        <button className="btn btn-primary" onClick={ () => window.open('https://www.javirocha.com/files/JR_Resume.pdf') }>Currículum Vitae</button>
      </Box>
    </div>
  )
}

export default Contact;
