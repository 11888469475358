import React from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../UI/Logo';

import classes from './Header.module.css';

function Header() {
  
  return (
    <header className={classes.header}>
      <Logo />
      <nav className={classes.nav}>
        <ul>
          <li><NavLink to="/about" exact activeClassName={classes.active}>About</NavLink></li>
          <li><NavLink to="/portfolio" exact activeClassName={classes.active}>Portfolio</NavLink></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
